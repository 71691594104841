import {Link} from 'gatsby'
import Appshell from '../../../components/Appshell'
import Background from '../../../uikit/Background'
import Text from '../../../uikit/Text'
import Button from '../../../uikit/Button'
import Flex from '../../../uikit/Flex'
import mobileBackground from '../../../assets/mobile-background.jpg'
import desktopBackground from '../../../assets/desktop-background.jpg'


const text = {
  title: 'darovanie predplatného',
  description: `Niet lepšieho darčeka, ako je dobrá kniha. Vlastne je - veľa dobrých kníh! Najprv vyberte typ
  predplatného pre osobu, ktorú chcete obdarovať. Ak by jej výber nevyhovoval, môžete ho kedykoľvek pozastaviť.`,
  subtitle: 'Môžete darovať:',
}

const Gift = () => {
  return (
    <Appshell>
      <Background desktopBackground={desktopBackground} mobileBackground={mobileBackground}>
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">
            {text.title}
          </Text>
          <p>
            {text.description}
          </p>
          <Text component="p">
            {text.subtitle}
          </Text>
          <Flex column crossAxis="center">
            <Button component={Link} isLink to="/subscription/gift/once">jednorazové predplatné</Button>
            <Button component={Link} isLink to="/subscription/gift/quaterly">3 mesiace (4/70)</Button>
            <Button component={Link} isLink to="/subscription/gift/semi-annually">6 mesiacov (2/39)</Button>
            <Button component={Link} isLink to="/subscription/gift/annually">12 mesiacov (2/39)</Button>
          </Flex>
        </Flex>
      </Background>
    </Appshell>
  )
}

export default Gift
